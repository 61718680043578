import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { setIsSelectingVehicle } from '../reducers/vehicle';
import { useDispatch, useSelector } from 'react-redux';
import Background from '../images/about_GoParts_bg_01.jpg';
import { storeInfo } from '../Utilities/Placeholders';
import Button from '../Common/Button';
import { contentData } from '../Whitelabel/websitesData';
import { Helmet } from 'react-helmet';
import websitesIndexData from '../websitesIndexData';
import { configData } from '../Whitelabel/websitesData';
import { buildHelmetData } from '../Utilities/HelmetTool';

function AboutUs() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isSelectingVehicle, selectedVersion } = useSelector(state => state.vehicle);
    const { title, content, link } = contentData.aboutUs;

    // Add helmetData using the unified utility
    const helmetData = buildHelmetData(configData, websitesIndexData, {
        pageName: 'Sobre',
        allowIndex: true, // About page should be indexed
        additionalKeywords: `sobre ${configData.defaultStore}, quem somos, peças originais, concessionária autorizada, peças genuínas`
    });

    const handleAboutUsClick = () => {
        window.open(`${link}`, '_blank');
    }

    const openVehicleSelect = () => {
        if (selectedVersion) {
          navigate('/categorias');
        } else {
          dispatch(setIsSelectingVehicle(!isSelectingVehicle));
        }
    };

    return (
        <div className='main fadeIn aboutUsBgStyle' style={{ backgroundImage: `url(${contentData.aboutUsBg})` }}>
            {helmetData && (
                <Helmet>
                    <title>{helmetData.title}</title>
                    <meta name="description" content={helmetData.description} />
                    
                    <meta name="robots" content={helmetData.robots} />
                    <meta name="googlebot" content={helmetData.robots} />
                    
                    <meta property="og:type" content={helmetData.social.type} />
                    <meta property="og:url" content={helmetData.social.url} />
                    <meta property="og:title" content={helmetData.social.title} />
                    <meta property="og:description" content={helmetData.social.description} />
                    <meta property="og:image" content={helmetData.social.image} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={helmetData.social.url} />
                    <meta property="twitter:title" content={helmetData.social.title} />
                    <meta property="twitter:description" content={helmetData.social.description} />
                    <meta property="twitter:image" content={helmetData.social.image} />
                    
                    <meta name="keywords" content={helmetData.keywords} />
                    <link rel="canonical" href={helmetData.canonical} />
                </Helmet>
            )}

            <div className='column center' style={{ color: 'white', maxWidth: '1280px', margin: 'auto auto', padding: '20px'}}>
                <div className='row'>
                    <div className='column aboutUsContainer'>
                        <h2>{title}</h2>
                        {content.map((text, index) => (
                            <span key={index}>{text}</span>
                        ))}
                        <div className='row' style={{gap: '10px'}}>
                            <Button
                                className='buttonColorSecond smallButton'
                                label={contentData.aboutUs.linkCta}
                                onClick={handleAboutUsClick}
                            />
                            <Button
                                className='buttonColorThird smallButton'
                                label='Comprar peças'
                                onClick={openVehicleSelect}
                            />
                        </div>
                    </div>
                    {!contentData.aboutUs.content.lenght > 2 &&
                        <div className='aboutUsEmpty'/>
                    }
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
