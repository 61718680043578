export const translateAttributeNames = {
    "ABS_SENSOR_LENGTH": "Comprimento do sensor ABS",
    "ABS_SENSOR_POSITION": "Posição do sensor ABS",
    "AC_COMPRESSOR_CLUTCH_COIL_HEIGHT": "Alto da bobina de compressor de ar condicionado",
    "AC_COMPRESSOR_CLUTCH_COIL_LENGTH": "Comprimento da bobina de compressor de ar condicionado",
    "AC_COMPRESSOR_CLUTCH_COIL_WIDTH": "Largura da bobina de compressor de ar condicionado",
    "ACCESSORIES_INCLUDED": "Acessórios incluídos",
    "ADJUSTABLE": "É ajustável",
    "AGE_GROUP": "Idade",
    "AIR_FILTER_DIAMETER": "Diâmetro do filtro de ar",
    "AIRBAG_INCLUDED": "Airbag incluído",
    "AIRBAG_POSITION": "Posição do airbag",
    "ANATEL_HOMOLOGATION_NUMBER": "Homologação Anatel Nº",
    "ANCHOR_TYPE": "Tipo de ancoragem",
    "ANCHORAGE_SYSTEMS": "Sistemas de ancoragem",
    "ANP_PRODUCT_REGISTRATION_NUMBER": "Número de registro de producto de ANP",
    "ARMREST_POSITION": "Posição do apoio de braços",
    "ATTACHMENT_TYPE": "Tipo de fixação",
    "AUDIO_FORMATS_SUPPORTED": "Formatos de áudio suportados",
    "AUTOMOTIVE_HORN_VOLTAGE": "Voltagem",
    "AUTOMOTIVE_PLACEMENT_SITE": "Lugar de colocação",
    "AUTOMOTIVE_PLACEMENT_TYPE": "Lugar de colocação",
    "AUTOMOTIVE_TIRE_ASPECT_RATIO": "Relação de aspecto",
    "AUXILIARY_POLES_NUMBER": "Quantidade de pólos auxiliares",
    "AXIS_POSITION": "Posição do eixo",
    "BACK_EMBLEM_CAR": "Emblema traseiro",
    "BACKLIGHT_COLOR": "Cor da luz de fondo",
    "BACKPACK_CAPACITY": "Capacidade",
    "BACKPACK_MATERIAL": "Material",
    "BACKPACK_TYPE": "Tipo de mochila",
    "BALL_DIAMETER": "Diâmetro da bola",
    "BALLASTS_TYPE": "Tipo de reatores",
    "BAR_DIAMETER": "Diâmetro do arame",
    "BAR_SHAPE": "Forma da barra",
    "BASE_DIAMETER": "Diâmetro da base",
    "BATTERY_AUTONOMY": "Autonomia da bateria",
    "BATTERY_CAPACITY": "Capacidade da bateria",
    "BEARING_DIAMETER": "Diâmetro do rolamento",
    "BEARING_HOLDER_WIDTH": "Largura do suporte do rolamento",
    "BEARING_INCLUDED": "Inclui rolamiento",
    "BEARING_RESISTANCE": "Resistência ao rolamento",
    "BEARINGS_INCLUDED": "Bronzinas incluídas",
    "BED_COVER_STYLE": "Estilo de capota marítima",
    "BELT_GROOVE_COMPRESSOR": "Canais da polia",
    "BELT_TENSIONER_BRAND": "Marca do rolamento",
    "BELT_TENSIONER_INCLUDED": "Inclui rolamento",
    "BELT_TENSIONER_PULLEY_BRAND": "Marca do tensor",
    "BELT_TENSIONER_PULLEY_INCLUDED": "Inclui tensor",
    "BENDIX_GEAR_TEETH": "Dentes bendix",
    "BIG_HOLE_DIAMETER": "Diâmetro do orifício grande",
    "BLEEDER_HOSES_INCLUDED": "Mangueiras de purga incluídas",
    "BLUETOOTH_VERSION": "Versão de Bluetooth",
    "BODY_DIAMETER": "Diâmetro do corpo",
    "BODY_THREAD_DIAMETER": "Diâmetro da rosca do corpo",
    "BOLT_DIAMETER": "Diâmetro do parafuso",
    "BOLT_LENGTH": "Comprimento do parafuso",
    "BOLT_THREAD_WIDTH": "Largura da rosca do parafuso",
    "BOLTS_PATTERN_NUMBER": "Quantidade de furos de montagem",
    "BOTTLE_COLD_DRINK_TEMPERATURE_CONSERVATION_TIME": "Tempo de conservação da bebida fria",
    "BOTTLE_HOT_DRINK_TEMPERATURE_CONSERVATION_TIME": "Tempo de conservação da bebida quente",
    "BOTTLE_MATERIAL": "Material da garrafa",
    "BRACELET_INCLUDED": "Puxador incluído",
    "BRAKE_BOOSTER_DIAPHRAGM_TYPE": "Tipo de diafragma do servo-freio",
    "BRAKE_BOOSTER_INCLUDED": "Servo-freio incluído",
    "BRAKE_BOOSTER_TYPE": "Tipo de servo-freio",
    "BRAKE_BOOSTER_USE": "Uso do servo-freio",
    "BRAKE_DISC_THICKNESS": "Espessura do disco de freio",
    "BRAKE_DISC_TYPE": "Tipo de disco de freio",
    "BRAKE_DRUM_DIAMETER": "Diâmetro do tambor de freio",
    "BRAKE_DRUM_INSIDE_DIAMETER": "Diâmetro interno do tambor de freio",
    "BRAKE_DRUM_NOMINAL_DIAMETER": "Diâmetro nominal do tambor de freio",
    "BRAKE_DRUM_OUTSIDE_DIAMETER": "Diâmetro externo do tambor de freio",
    "BRAKE_HYDRAULIC_HOSE_LENGTH": "Comprimento",
    "BRAKE_LIGHT_POSITION": "Posição",
    "BRAKE_LIGHT_SWITCH_TYPE": "Tipo de interruptor de luz de freio",
    "BRAND": "Marca",
    "BULB_COLOR": "Cor da lâmpada",
    "BULB_CONNECTOR_TYPE": "Tipo de conector da lâmpada",
    "BULB_SIZE": "Modelo de lâmpadas",
    "BULB_TECHNOLOGY": "Tecnologia da lâmpada",
    "BULB_TYPE": "Tipo de lâmpada",
    "BULBS_INCLUDED": "Lâmpadas incluídas",
    "BULBS_NUMBER": "Quantidade de lâmpadas",
    "BULBS_TYPE": "Tipo de iluminação",
    "BUMPER_BRACKET_LENGTH": "Comprimiento do soporte de pára-choques",
    "BUMPER_BRACKET_MATERIAL": "Material do suporte de pára-choques",
    "BUMPER_IMPACT_ABSORBER_LENGTH": "Comprimento do alma do parachoque",
    "BUMPER_IMPACT_ABSORBER_WIDTH": "Anchura do alma do parachoque",
    "BURST_PRESSURE": "Pressão de ruptura",
    "BUSHING_INCLUDED": "Buchas incluídas",
    "BUSHING_MATERIAL": "Material da bucha",
    "BUTTONS_NUMBER": "Quantidade de botões",
    "CABIN_TYPE": "Tipo de cabine",
    "CAMSHAFT_INCLUDED": "Comando de válvulas incluído",
    "CAP_INCLUDED": "Tampa incluída",
    "CAR_AC_EXPANSION_VALVE_LENGTH": "Comprimento da válvula de expansão de ar condicionado",
    "CAR_AC_EXPANSION_VALVE_WIDTH": "Largura da válvula de expansão de ar condicionado",
    "CAR_AXIS_POSITION": "Eixo",
    "CAR_BATTERY_VOLTAGE": "Voltagem",
    "CAR_BRAND": "Marca do carro",
    "CAR_BUCKET_SEAT_POSITION": "Posição do assento",
    "CAR_DOOR_HINGE_MATERIAL": "Material da dobradiça de porta para carro",
    "CAR_DOOR_HINGE_POSITION": "Posição da dobradiça de porta para carro",
    "CAR_FILTER_DESIGN": "Estrutura",
    "CAR_FILTER_MATERIAL": "Elemento",
    "CAR_FUEL_TANK_CAPACITY": "Capacidade",
    "CAR_GEARBOX_NUMBER_OF_SPEEDS": "Quantidade de marchas",
    "CAR_JACK_FORMAT": "Formato do macaco",
    "CAR_LIGHT_BULB_CONNECTOR_TYPE": "Tipo de conector",
    "CAR_MODEL": "Modelo do carro",
    "CAR_MOLDINGS_TYPE": "Tipo",
    "CAR_SPOILER_MATERIAL": "Material",
    "CAR_WHEEL_COVER_TYPE": "Tipo de calota",
    "CARBURETOR_ACCELERATOR_CABLE_COVER_LENGTH": "Comprimento da capa do cabo de acelerador",
    "CARBURETOR_ACCELERATOR_CABLE_LENGTH": "Comprimento do cabo de acelerador",
    "CARBURETOR_ACCELERATOR_CABLE_MATERIAL": "Material do cabo de acelerador",
    "CASTLE_NUT_INCLUDED": "Porca incluída",
    "CDI_MODULE_VOLTAGE": "Voltagem",
    "CELLPHONE_ADJUSTMENT": "Fixação do celular",
    "CHANNELS_NUMBER": "Quantidade de canais",
    "CHARACTER": "Personagem",
    "CHEST_CIRCUMFERENCE": "Contorno do peito",
    "CHROME": "Cromado",
    "CLAMPS_INCLUDED": "Grampos incluídos",
    "CLASS": "Classe",
    "CLAW_MATERIAL": "Material da garra",
    "CLIPS_INCLUDED": "Presilhas incluídas",
    "CLOSURE_TYPES": "Tipos de fecho",
    "CLUTCH_BEARING_INSIDE_DIAMETER": "Diâmetro interno do rolamento de embreagem",
    "CLUTCH_BEARING_OUTSIDE_DIAMETER": "Diâmetro externo do rolamento de embreagem",
    "CLUTCH_BEARING_RACE_INCLUDED": "Pista do rolamento de embreagem incluída",
    "CLUTCH_CABLE_LENGTH": "Comprimento do cabo de embreagem",
    "CLUTCH_DISC_DIAMETER": "Diâmetro do disco de embreagem",
    "CLUTCH_DISC_MATERIAL": "Material do disco de embreagem",
    "CLUTCH_FORK_INSIDE_DIAMETER": "Diâmetro interno do garfo de embreagem",
    "CLUTCH_FORK_LENGTH": "Comprimento do garfo de embreagem",
    "CLUTCH_FORK_OUTSIDE_DIAMETER": "Diâmetro externo do garfo de embreagem",
    "CLUTCH_SLAVE_CYLINDER_LENGTH": "Comprimento do atuador de embreagem",
    "CLUTCH_TYPE": "Tipo de embreagem",
    "COIL_TYPE": "Tipo de bobina",
    "COILS_NUMBER": "Quantidade de bobinas",
    "COLOR_TEMPERATURE": "Temperatura de cor",
    "COLOR": "Cor",
    "COMPATIBLE_BRANDS": "Marcas compatíveis",
    "COMPATIBLE_CELLPHONE_AND_GPS_BRANDS": "Marcas de celulares e GPS compatíveis",
    "COMPATIBLE_CELLPHONE_AND_GPS_MODELS": "Modelos de celulares e GPS compatíveis",
    "COMPATIBLE_MOTORCYCLES_MODELS": "Modelos de motos compatíveis",
    "COMPATIBLE_OS_NAMES": "Nomes dos sistemas operacionais compatíveis",
    "COMPATIBLE_PISTON_BORE_DIAMETER": "Diâmetro do furo do pistão compatível",
    "COMPOSITION": "Composição",
    "COMPRESSED_LENGTH": "Comprimento comprimido",
    "COMPRESSOR_DIAMETER": "Diâmetro do compressor",
    "COMPRESSOR_PULLEY_DIAMETER": "Diâmetro da polia",
    "COMPRESSOR_REFRIGERANT_TYPE": "Tipo de refrigerante",
    "CONAMA_LICENSE_NUMBER": "Número de licença do CONAMA",
    "CONNECTING_ROD_DIAMETER": "Diâmetro da biela",
    "CONNECTION_DIAMETER": "Diâmetro da conexão",
    "CONNECTION_POSITION": "Posição da conexão",
    "CONNECTION_SIZE": "Tamanho da conexão",
    "CONNECTOR_GENDER": "Gênero do conector",
    "CONNECTOR_QUANTITY": "Quantidade de conectores",
    "CONNECTORS_NUMBER": "Quantidade de conectores",
    "CONTACTOR_AND_RELAY_PRODUCT_TYPE": "Tipo de produto",
    "CONTAINER_TYPE": "Tipo de recipiente",
    "CONTAINS_OIL_BAFFLES": "Contém defletores de óleo",
    "CONTROL_ARM_POSITION": "Posiçao da bandeja",
    "CORE_MATERIAL": "Material do núcleo",
    "COUPLINGS_TYPE": "Tipo de pontas",
    "COVER_MATERIAL": "Material do pano",
    "CRANKSHAFT_LENGTH": "Comprimento do virabrequim",
    "CRANKSHAFT_SEALS_INCLUDED": "Selos de virabrequim incluídos",
    "CV_JOINT_BOOT_MATERIAL": "Material da coifa homocinética",
    "CV_JOINT_BOOT_TYPE": "Tipo de coifa homocinética",
    "CV_JOINTS_INCLUDED": "Cruzetas incluídas",
    "CYLINDER_DIAMETER": "Diâmetro do cilindro",
    "CYLINDER_HEAD_TYPE": "Tipo de cabeça de cilindro",
    "CYLINDERS_NUMBER": "Quantidade de cilindros",
    "DEPTH": "Profundidade",
    "DESIGN": "Desenho",
    "DETAILED_MODEL": "Modelo detalhado",
    "DIAMETER": "Diâmetro",
    "DIAPHRAGM_DIAMETER": "Diâmetro do diafragma",
    "DIFFERENTIAL_COVER_INCLUDED": "Caixa do diferencial incluída",
    "DIFFERENTIAL_FLUID_CAPACITY": "Capacidade de fluido do diferencial",
    "DIFFERENTIAL_GEAR_RATIO": "Taxa de transmissão do diferencial",
    "DIRECTION_ROTATION": "Sentido de rotação",
    "DISPLAY_SIZE": "Tamanho da tela",
    "DISTANCE_BETWEEN_HOLES": "Distância entre furos",
    "DISTANCE_BETWEEN_INJECTORS": "Distância entre injetores",
    "DISTANCE_FROM_CLUTCH_TO_MOUNTING_FLANGE": "Distância entre a polia e o eixo de montagem",
    "DOORS_NUMBER": "Quantidade de portas",
    "DOT_NUMBER": "Número de DOT",
    "DRAIN_PLUG_INCLUDED": "Bujão de drenagem incluído",
    "DRIVE_SHAFT_DIAMETER": "Diâmetro do eixo cardã",
    "DRIVE_SHAFT_SLIP_YOKES_INCLUDED": "Juntas corretoras do eixo cardã incluídas",
    "DRIVE_SHAFT_TYPE": "Tipo de eixo cardã",
    "DRIVER_WIPER_MEASURE": "Medida da palheta condutor",
    "EGR_VALVE_MOUNTING_HOLE_QUANTITY": "Quantidade de furos de montagem da válvula EGR",
    "EGR_VALVE_OPERATION_TYPE": "Tipo de operação da válvula EGR",
    "ELECTRICAL_MAXIMUM_POWER": "Potência máxima",
    "ELECTROLYTE_VOLUME": "Volume de eletrólito",
    "END_1_TYPE": "Tipo de punta 1",
    "END_2_TYPE": "Tipo de punta 2",
    "END_THREAD_DIAMETER": "Diâmetro da rosca",
    "ENDS_INSIDE_DIAMETER": "Diâmetro interno das pontas",
    "ENDS_OUTSIDE_DIAMETER": "Diâmetro externo das pontas",
    "ENGINE_BEARING_INSIDE_DIAMETER": "Diâmetro interno da bronzina de motor",
    "ENGINE_BEARING_OUTSIDE_DIAMETER": "Diâmetro externo da bronzina de motor",
    "ENGINE_CONNECTING_ROD_LENGTH": "Comprimento da biela de motor",
    "ENGINE_CONNECTING_ROD_TYPE": "Tipo de biela de motor",
    "ENGINE_CONTROL_MODULE_LENGTH": "Comprimento do módulo de controle do motor",
    "ENGINE_CONTROL_MODULE_WIDTH": "Anchura do módulo de controle do motor",
    "ENGINE_COOLING_FAN_CLUTCH_DIAMETER": "Diâmetro da polia viscosa",
    "ENGINE_COOLING_FAN_CLUTCH_ROTATION": "Rotação da polia viscosa",
    "ENGINE_COOLING_FAN_CLUTCH_TYPE": "Tipo do polia viscosa",
    "ENGINE_COOLING_FAN_MOTOR_POWER_REQUIREMENT": "Energia necessária do motor de eletro-ventilador",
    "ENGINE_CRANKSHAFT_PULLEY_BELT_TYPE": "Tipo de correia da polia do virabrequim",
    "ENGINE_CRANKSHAFT_PULLEY_INTERNAL_DIAMETER": "Diâmetro interno da polia do virabrequim",
    "ENGINE_CRANKSHAFT_PULLEY_MATERIAL": "Material da polia do virabrequim",
    "ENGINE_CRANKSHAFT_PULLEY_WIDTH": "Largura da polia do virabrequim",
    "ENGINE_CYLINDER_HEAD_BOLT_DIAMETER": "Diâmetro do parafuso de cabeçote de cilindro",
    "ENGINE_CYLINDER_HEAD_BOLT_HEAD_TYPE": "Tipo de cabeça do parafuso de cabeçote de cilindro",
    "ENGINE_CYLINDER_HEAD_BOLT_LENGTH": "Comprimento do parafuso de cabeçote de cilindro",
    "ENGINE_CYLINDER_HEAD_MATERIAL": "Material",
    "ENGINE_CYLINDER_HEAD_TOTAL_HEIGHT": "Altura total",
    "ENGINE_CYLINDER_HEAD_TOTAL_LENGTH": "Comprimento total",
    "ENGINE_INTAKE_HOSE_INSIDE_DIAMETER": "Diâmetro interno da mangueira de admissão",
    "ENGINE_INTAKE_HOSE_LENGTH": "Comprimento da mangueira de admissão",
    "ENGINE_INTAKE_HOSE_MATERIAL": "Material da mangueira de admissão",
    "ENGINE_INTAKE_HOSE_OUTSIDE_DIAMETER": "Diâmetro externo da mangueira de admissão",
    "ENGINE_INTAKE_MANIFOLD_MATERIAL": "Material do coletor de admissão",
    "ENGINE_OIL_DIPSTICK_LENGTH": "Comprimento da vareta de nivel de óleo",
    "ENGINE_OIL_DIPSTICK_MATERIAL": "Material da vareta de nivel de óleo",
    "ENGINE_OIL_DIPSTICK_TUBE_INCLUDED": "Tubo da vareta de nivel de óleo incluído",
    "ENGINE_OIL_DIPSTICK_WIDTH": "Largura da vareta de nivel de óleo",
    "ENGINE_OIL_GRADE": "Grau do óleo de motor",
    "ENGINE_OIL_PAN_CAPACITY": "Capacidade do cárter de motor",
    "ENGINE_OIL_PRESSURE_SENSOR_TERMINAL_TYPE": "Tipo de terminal do sensor de pressão de óleo",
    "ENGINE_OIL_TYPE": "Tipo de óleo de motor",
    "ENGINE_OIL_VOLUME": "Volume do óleo de motor",
    "ENGINE_PISTON_INSIDE_DIAMETER": "Diâmetro interno do pistão de motor",
    "ENGINE_PISTON_MATERIAL_TYPE": "Tipo de material do pistão de motor",
    "ENGINE_PISTON_OUTSIDE_DIAMETER": "Diâmetro externo do pistão de motor",
    "ENGINE_PISTON_PIN_LENGTH": "Largo da trava do pistão de motor",
    "ENGINE_PISTON_RING_SET_MATERIAL": "Material",
    "ENGINE_POWER": "Potência do motor",
    "ENGINE_ROCKER_ARM_TYPE": "Tipo de balancim de motor",
    "ENGINE_STROKE": "Tempos do motor",
    "ENGINE_TAPPET_GUIDE_HOLD_DIAMETER": "Diâmetro do tucho hidráulico",
    "ENGINE_TAPPET_GUIDE_HOLD_LENGTH": "Comprimento do tucho hidráulico",
    "ENGINE_TAPPET_GUIDE_HOLD_WIDTH": "Largura do tucho hidráulico",
    "ENGINE_VALVE_COVER_TYPE": "Tipo de tampas de válvula",
    "ENGINE_VALVE_TYPE": "Tipo de válvula de motor",
    "ENGINE_YEAR": "Ano do motor",
    "EXHAUST_DURATION_AT_0_50_INCH_LIFT": "Duração do escape em elevação de 0,50 polegadas",
    "EXHAUST_MANIFOLD_GASKET_SET_INSIDE_DIAMETER": "Diâmetro interno do jogo de juntas do coletor de escapamento",
    "EXHAUST_MANIFOLD_GASKET_SET_OUTSIDE_DIAMETER": "Diâmetro externo do jogo de juntas do coletor de escapamento",
    "EXHAUST_PIPE_DIAMETER": "Diâmetro do tubo de escapamento",
    "EXHAUST_PIPE_LENGTH": "Comprimento do tubo de escapamento",
    "EXHAUST_VALVE_LIFT": "Elevação da válvula de escape",
    "EXTENDED_LENGTH": "Comprimento estendido",
    "EXTERIOR_DOOR_HANDLE_MATERIAL": "Material",
    "EXTERIOR_MATERIALS": "Materiais do exterior",
    "EXTERNAL_MATERIAL": "Material externo",
    "EXTERNAL_NOISE": "Ruído externo",
    "EXTERNAL_POCKETS_NUMBER": "Quantidade de bolsos externos",
    "FABRIC_DESIGN": "Desenho do tecido",
    "FABRIC_TYPE": "Tipo de tecido",
    "FILTER_FORM": "Forma do filtro",
    "FILTER_SHAPE": "Forma do filtro",
    "FINISH": "Acabamento",
    "FITTINGS_INCLUDED": "Acessórios incluídos",
    "FLOAT_LENGTH": "Comprimento da boia",
    "FLUID_LEVEL_SENSOR_INCLUDED": "Sensor de nivel de fluido incluído",
    "FMSI_NUMBER": "Código FMSI",
    "FOG_LIGHT_MATERIAL": "Material",
    "FOOTWEAR_MATERIAL": "Material do calçado",
    "FORK_DIAMETER": "Diâmetro do garfo",
    "FREQUENCY_RANGE": "Faixa de frequência",
    "FRICTION_MATERIAL_BONDING_TYPE": "Tipo de fixação do material de fricção",
    "FRONT_EMBLEM_CAR": "Emblema dianteiro",
    "FUEL_INJECTION_PIPE_LENGTH": "Comprimento do cano de injetores",
    "FUEL_INJECTION_PIPE_SHAPE": "Forma do cano de injetores",
    "FUEL_INJECTION_RAIL_LENGTH": "Comprimento da flauta de combustível",
    "FUEL_SYSTEM_TYPE": "Tipo de sistema de combustível",
    "FUEL_TYPE": "Tipo de combustível",
    "GARMENT_TYPE": "Tipo de roupa",
    "GASKET_OR_SEAL_INCLUDED": "Junta ou selo incluído",
    "GASKETS_INCLUDED": "Juntas incluídas",
    "GEARS_POSITIONS": "Posições dos engrenagens",
    "GENDER": "Gênero",
    "GLOW_PLUG_LENGTH": "Comprimento total da vela aquecedora",
    "GLOW_PLUG_MATERIAL": "Material da vela aquecedora",
    "GLOW_PLUG_VOLTAGE": "Voltagem da vela aquecedora",
    "GROMMETS_INCLUDED": "Aneles isolantes incluídos",
    "GROOVE_QUANTITY": "Número de sulcos",
    "HAND_STRAPS_COLOR": "Cor das alças",
    "HANDBAG_TYPE": "Tipo de bolsa",
    "HANDLEBAR_DIAMETER": "Diâmetro do guidão",
    "HARDWARE_FINISH": "Acambamento dos ferragens",
    "HAT_AND_CAP_TYPE": "Tipo de chapéu",
    "HEAD_BOLTS_INCLUDED": "Parafusos de cabeçote incluídos",
    "HEAD_DIAMETER": "Diâmetro da cabeça",
    "HEADLIGHT_FRAME_MATERIAL": "Material do quadro do farol dianteiro",
    "HEADLIGHT_FRAME_POSITION": "Posição do quadro do farol dianteiro",
    "HEIGHT": "Altura",
    "HELMET_SIZE": "Tamanho do capacete",
    "HELMET_TYPE": "Tipo de capacete",
    "HIGHT": "Comprimento",
    "HIP_CIRCUMFERENCE": "Contorno do quadril",
    "HOOD_HINGE_LENGTH": "Comprimento da dobradiça de capô",
    "HOOD_HINGE_WIDTH": "Largura da dobradiça de capô",
    "HOOD_RELEASE_CABLE_LENGTH": "Comprimento do cabo de abertura de capô",
    "HOOK_DIAMETER": "Diâmetro do anel",
    "HOOK_LENGTH": "Comprimento do anel",
    "HOSE_DIAMETER": "Diâmetro da mangueira",
    "HOSE_LENGTH": "Comprimento da mangueira",
    "HOSE_MATERIAL": "Material da mangueira",
    "HOSE_POSITION": "Posição da mangueira",
    "HOSES_INCLUDED": "Mangueiras incluídas",
    "HOUSING_INCLUDED": "Invólucro incluído",
    "IDLER_ARM_SIDE": "Lado do braços de suspensão",
    "IGNITION_SWITCH_ACTUATOR_MATERIAL": "Material do comutador de ignição",
    "IMPEDANCE": "Impedância",
    "INBOARD_SPLINE_QUANTITY": "Quantidade de estrias internas",
    "INCLUDES_ABS_SENSOR": "Sensor ABS",
    "INCLUDES_ABS": "ABS",
    "INCLUDES_AC_FILTER": "Filtro de ar condicionado",
    "INCLUDES_AIR_FILTER": "Filtro de ar",
    "INCLUDES_ALIGNMENT_TOOL": "Centralizador mecânico",
    "INCLUDES_ASSEMBLY_KIT": "Kit de montagem",
    "INCLUDES_BACK_HOUSING": "Caixa traseira",
    "INCLUDES_BALL_JOINT": "Pivô",
    "INCLUDES_BALLASTS": "Reatores",
    "INCLUDES_BEARING": "Rolamento",
    "INCLUDES_BRAKE_HYDRAULIC_HOSE": "Flexível hidráulico de freio",
    "INCLUDES_BRAKE_PADS": "Pastilhas de freio",
    "INCLUDES_BULB": "Lâmpada",
    "INCLUDES_BUSHING": "Bucha",
    "INCLUDES_CABLES": "Fios",
    "INCLUDES_CALIBRATION_CERTIFICATE": "Certificado de calibração",
    "INCLUDES_CAP": "Tampa",
    "INCLUDES_CATALYTIC": "Catalisador",
    "INCLUDES_CELL_BATTERIES": "Pilhas",
    "INCLUDES_CLUTCH_PLATE": "Platô",
    "INCLUDES_CONTROL": "Controle",
    "INCLUDES_CROWN": "Coroa",
    "INCLUDES_DISC": "Disco",
    "INCLUDES_DRIVE_SHAFT": "Eixo de transmissão",
    "INCLUDES_DRIVE_SPROCKET": "Engraneagem motriz",
    "INCLUDES_EMBLEM": "Emblema",
    "INCLUDES_ENGINE_OIL_COOLER": "Refrigerador do óleo de motor",
    "INCLUDES_FAN_ASSEMBLY": "Defletor",
    "INCLUDES_FILTER": "Filtro",
    "INCLUDES_FLYWHEEL": "Volante",
    "INCLUDES_FOG_LIGHTS": "Faróis de nevoeiro",
    "INCLUDES_FRAME": "Armação",
    "INCLUDES_FUEL_FILTER": "Filtro de combustivel",
    "INCLUDES_GASKET_OR_SEAL": "Junta ou selo",
    "INCLUDES_GASKET": "Junta",
    "INCLUDES_GASKETS": "Juntas",
    "INCLUDES_GREASE": "Graxa",
    "INCLUDES_HARNESS": "Arnês",
    "INCLUDES_HEADREST": "Apoio de cabeça",
    "INCLUDES_HINGES": "Dobradiças",
    "INCLUDES_HOSES_CLAMPS": "Braçadeiras",
    "INCLUDES_IDLE_AIR_CONTROL_VALVE": "Atuador de marcha lenta",
    "INCLUDES_INNER_TIE_ROD": "Terminais",
    "INCLUDES_INSTALLATION_KIT": "Kit de instalação",
    "INCLUDES_INSTALLATION_MANUAL": "Manual de instalação",
    "INCLUDES_KEYS": "Chaves",
    "INCLUDES_LOCK_CYLINDER": "Cilindro de chave",
    "INCLUDES_LUBRICANT": "Lubrificante",
    "INCLUDES_MASTER_CYLINDER": "Cilindro principal",
    "INCLUDES_MIRROR_TURN_SIGNAL_INDICATOR": "Indicador de giro do espelho",
    "INCLUDES_MIRROR": "Espelho",
    "INCLUDES_MOLDINGS": "Molduras",
    "INCLUDES_MOUNTING_ACCESSORIES": "Acessórios para a montagem",
    "INCLUDES_MOUNTING_BRACKETS": "Grampos",
    "INCLUDES_MOUNTING_HARDWARE": "Kit de montagem",
    "INCLUDES_MOUNTING_SCREWS": "Parafusos de montagem",
    "INCLUDES_MUFFLER": "Silencioso",
    "INCLUDES_OIL_FILTER": "Filtro de óleo",
    "INCLUDES_OIL": "Óleo",
    "INCLUDES_OUTER_TIE_RODS": "Terminal de direção",
    "INCLUDES_PEDAL_ROD_EXTENSION": "Extensão da barra do pedal",
    "INCLUDES_PISTONS": "Pistões",
    "INCLUDES_POSITION_SENSOR": "Sensor de posição",
    "INCLUDES_PULLEY": "Polia",
    "INCLUDES_REMOTE_CONTROL": "Controle remoto",
    "INCLUDES_RESERVOIR": "Reservatório",
    "INCLUDES_RINGS": "Aros",
    "INCLUDES_SCREWS": "Parafusos",
    "INCLUDES_SEALS": "Juntas",
    "INCLUDES_SECURITY_VALVE": "Válvula de segurança",
    "INCLUDES_SOLENOID": "Solenóide",
    "INCLUDES_SUPPORT": "Suporte",
    "INCLUDES_THROW_OUT_BEARING": "Rolimã de impulso",
    "INCLUDES_TRANSMISSION_OIL_COOLER": "Refrigerante do óleo de transmissão",
    "INCLUDES_WATER_PUMP": "Bomba de água",
    "INCLUDES_WEAR_SENSORS": "Sensores de desgaste",
    "INJECTION_TYPE": "Tipo de injeção",
    "INJECTOR_QUANTITY": "Quantidade de injetores",
    "INLET_CONNECTION_DIAMETER": "Diâmetro do conector de entrada",
    "INLET_DIAMETER": "Diâmetro de entrada",
    "INLET_FITTING_GENDER": "Gênero da conexão de entrada",
    "INLET_HOLE_NUMBER": "Quantidade de dutos de entrada",
    "INNER_MATERIALS": "Materiais do interior",
    "INNER_RING_WIDTH": "Largura do anel interno",
    "INNER_TIE_ROD_LENGTH": "Comprimento",
    "INNER_TIE_ROD_SIDE": "Lado",
    "INPUT_CONNECTORS": "Conectores de entrada",
    "INPUT_DIAMETER": "Diâmetro de entrada",
    "INPUT_SIZE": "Diâmetro de entrada",
    "INSIDE_DIAMETER": "Diâmetro interno",
    "INSTALLATION_PLACEMENT": "Lugar de colocação",
    "INSTALLATION_TYPE": "Tipo de instalação",
    "INSULATION_MATERIAL": "Material do isolante",
    "INSULATION_OUTSIDE_DIAMETER": "Diâmetro exterior do isolante",
    "INTAKE_DIAMETER": "Diâmetro de admissão",
    "INTAKE_DURATION_AT_0_50_INCH_LIFT": "Duração da admissão em elevação de 0,50 polegadas",
    "INTAKE_MANIFOLD_GASKET_SET_INSIDE_DIAMETER": "Diâmetro interno do jogo de juntas do coletor de admissão",
    "INTAKE_MANIFOLD_GASKET_SET_OUTSIDE_DIAMETER": "Diâmetro externo do jogo de juntas do coletor de admissão",
    "INTAKE_VALVE_LIFT": "Elevação da válvula de admissão",
    "INTEGRATED_INTERNAL_ENGINE_OIL_COOLER": "Refrigerador interno do óleo do motor integrado",
    "INTEGRATED_INTERNAL_TRANSMISSION_OIL_COOLER": "Refrigerador interno do óleo de transmissão integrado",
    "INTERCOMMUNICATORS_NUMBER": "Quantidade de intercomunicadores",
    "INTERCOOLER_HOSE_ENDS_INSIDE_DIAMETER": "Diâmetro interno das pontas da mangueira de intercooler",
    "INTERCOOLER_HOSE_ENDS_OUTSIDE_DIAMETER": "Diâmetro externo das pontas da mangueira de intercoleer",
    "INTERCOOLER_HOSE_LENGTH": "Comprimento da mangueira de intercooler",
    "INTERCOOLER_HOSE_MATERIAL": "Material da mangueira de intercooler",
    "INTERCOOLER_TYPE": "Tipo de intercooler",
    "INTERIOR_MATERIAL": "Material do interior",
    "INTERNAL_MATERIAL": "Material interno",
    "INTERNAL_POCKETS_NUMBER": "Quantidade de bolsos internos",
    "IS_ADJUSTABLE": "Ajustável",
    "IS_ANTI_FOG": "Antiembaçante",
    "IS_ANTI_REFLECTIVE": "Anti-refletor",
    "IS_ANTICORROSIVE": "Anticorrosivo",
    "IS_AUTO_DIMMABLE": "Fotocrômico",
    "IS_BLOCKABLE": "Bloqueável",
    "IS_BPA_FREE": "Livre de BPA",
    "IS_COMPLETE_SET": "Conjunto completo",
    "IS_CUSTOMIZED": "Personalizado",
    "IS_DETACHABLE": "Desmontável",
    "IS_ELECTRIC_LOCK": "Fechadura elétrica",
    "IS_ELECTRIC": "Elétrico",
    "IS_ELECTRICAL": "Elétrica",
    "IS_FLAMMABLE": "Inflamável",
    "IS_FOLDABLE": "Dobrável",
    "IS_FOLDING": "Rebatível",
    "IS_HEATED": "Aquecida",
    "IS_IMPERMEABLE": "Impermeável",
    "IS_LOCKABLE": "Segurável",
    "IS_MATCHED": "Casada",
    "IS_NON_SLIP": "Antiderrapante",
    "IS_OEM_REPLACEMENT": "Reposição original",
    "IS_ORIGINAL": "Original",
    "IS_PRE_GREASED": "Pre lubrificada",
    "IS_READY_TO_PAINT": "Pronto para pintar",
    "IS_RESISTIVE": "Resistivo",
    "IS_RETRACTABLE": "Retrátil",
    "IS_REVERSIBLE": "Reversível",
    "IS_RUN_FLAT": "Run flat",
    "IS_SHEET": "Cartela",
    "IS_SLOTTED": "Ranhurado",
    "IS_SPORTING": "Esportivo",
    "IS_SPORTIVE": "Esportiva",
    "IS_STREET_LEGAL": "Legal para calle",
    "IS_THERMAL": "Térmico",
    "IS_THERMIC": "Térmica",
    "IS_ULTRALIGHT": "Ultra leve",
    "IS_VEGAN": "Vegano",
    "IS_WATERPROOF": "À prova d\'água",
    "JACKET_MATERIAL": "Material",
    "KNOB_INLCUDED": "Manopla incluída",
    "LENGTH_TYPE": "Tipo de comprimento",
    "LENGTH": "Comprimento",
    "LENS_COLOR": "Cor da lente",
    "LENS_MATERIAL": "Material da lente",
    "LEVER_LENGTH": "Comprimento da alavanca",
    "LID_OPENING_SYSTEM": "Sistema de abertura da tampa",
    "LIGHT_COLOR": "Cor da luz",
    "LIGHT_TYPE": "Tipo de luz",
    "LINE": "Linha",
    "LINKS_NUMBER": "Número de ligações",
    "LIQUID_FILLER_TYPE": "Tipo de preenchimento líquido",
    "LOAD_INDEX": "Índice de carga",
    "LOAD_RANGE": "Faixa de carga",
    "LOAD_VOLTAGE": "Voltagem de carga",
    "LOCATION": "Localização",
    "MAIN_JOURNAL_BUSHING_DIAMETER": "Diâmetro do munhão principal",
    "MAIN_MATERIAL": "Material principal",
    "MAIN_POLES_NUMBER": "Quantidade de pólos principais",
    "MANUAL_TRANSMISSION_SHIFT_CABLE_LENGTH": "Comprimento do cabo seletor de marcha",
    "MANUAL_TRANSMISSION_SHIFT_LEVER_BOOT_MATERIAL": "Material",
    "MANUFACTURER_TIRE_SIZE": "Tamanho",
    "MATERIAL_CAR_EMBLEM": "Material",
    "MATERIAL_FINISH": "Acabamento do material",
    "MATERIAL": "Material",
    "MATERIALS": "Materiais",
    "MAX_CHEST_GIRTH": "Circunferência máxima do peito",
    "MAX_CONNECTED_RIDERS_NUMBER": "Quantidade máxima de motociclistas conectados",
    "MAX_HEIGHT_LIFTED": "Máxima altura de levantamento",
    "MAX_HEIGHT": "Altura máxima",
    "MAX_HELMETS_CAPACITY": "Capacidade máxima de capacetes",
    "MAX_INPUT_VOLTAGE": "Voltagem máxima de entrada",
    "MAX_LENGTH": "Comprimento máximo",
    "MAX_OUTPUT_CURRENT": "Corrente máxima de saída",
    "MAX_OUTPUT_VOLTAGE": "Voltagem máxima de saída",
    "MAX_PRESSURE": "Pressão máxima",
    "MAX_REACH": "Alcance máximo",
    "MAX_RECOMMENDED_AGE": "Idade máxima recomendada",
    "MAX_SPEED_MEASURED": "Velocidade máxima medida",
    "MAX_SUPPORTED_VOLUME": "Máximo volume suportado",
    "MAX_SUPPORTED_WEIGHT": "Peso máximo suportado",
    "MAX_TEMPERATURE_SUPPORTED": "Temperatura máxima suportada",
    "MAX_WEIGHT_SUPPORTED": "Peso máximo suportado",
    "MAXIMUM_BLADE_DIAMETER": "Diâmetro máximo das pás",
    "MAXIMUM_OPENING_ANGLE": "Ângulo máximo de abertura",
    "MAXIMUM_WORKING_PRESSURE": "Pressão de trabalho máxima",
    "MIN_CHEST_GIRTH": "Circunferência mínima do peito",
    "MIN_HEIGHT_LIFTED": "Mínima altura de levantamento",
    "MIN_HEIGHT": "Altura mínima",
    "MIN_INPUT_VOLTAGE": "Voltagem mínima de entrada",
    "MIN_LENGTH": "Comprimento mínimo",
    "MIN_OUTPUT_VOLTAGE": "Voltagem mínima de saída",
    "MIN_RECOMMENDED_AGE": "Idade mínima recomendada",
    "MIN_TEMPERATURE_SUPPORTED": "Temperatura mínima suportada",
    "MIN_WEIGHT_SUPPORTED": "Peso mínimo suportado",
    "MINIMUM_SCREEN_SIZE": "Tamanho mínimo da tela",
    "MIRROR_LOCATION": "Posição do espelho",
    "MODEL": "Modelo",
    "MOTOR_DIAMETER": "Diâmetro do motor",
    "MOTORCYCLE_CASE_LOCATION": "Localização do baú",
    "MOTORCYCLE_COVER_SIZE": "Tamanho",
    "MOTORCYCLE_GLOVES_TYPES": "Tipos de luvas para motociclistas",
    "MOTORCYCLE_GRIP_TYPE": "Tipo de aderência",
    "MOTORCYCLE_OIL_FILTER_DIAMETER": "Diâmetro do filtro de óleo",
    "MOTORCYCLE_OIL_FILTER_THREAD_DIAMETER": "Diâmetro da rosca do filtro de óleo",
    "MOTORCYCLE_RIDING_STYLE": "Estilo de condução em moto",
    "MOTORCYCLE_SPEEDOMETER_TYPE": "Tipo de velocímetro",
    "MOTORCYCLE_TERRAIN_TYPE": "Tipo de terreno",
    "MOTORCYCLE_TYPE": "Tipo de moto",
    "MOUNT_POSITION": "Posição de montagem",
    "MOUNTING_BRACKET_INCLUDED": "Base incluída",
    "MOUNTING_BRACKETS_INCLUDED": "Grampos incluídos",
    "MOUNTING_HOLE_QUANTITY": "Quantidade de furos de montagem",
    "MOUNTING_SIDE": "Lado de montagem",
    "MOUNTING_TYPE": "Tipo de montagem",
    "MOUTH_TYPE": "Tipo de bico",
    "MPN": "MPN",
    "MUD_GUARD_POSITIONS": "Posições",
    "MUFFLER_LENGTH": "Comprimento do silencioso",
    "MUFFLER_MATERIAL": "Material do silencioso",
    "MUFFLER_SHAPE": "Forma do silencioso",
    "MUFFLER_WIDTH": "Largura do silencioso",
    "NERF_BARS_MATERIAL": "Material",
    "NET_VOLUME": "Volume líquido",
    "NUMBER_OF_FANS": "Quantidade de ventiladores",
    "NUMBER_OF_MOUNTING_SCREWS": "Número de parafusos de montagem",
    "NUMBER_OF_OPERABLE_WINDOWS": "Quantidade de janelas operáveis",
    "NUMBER_OF_PIECES_BY_KIT": "Quantidade de piezas por kit",
    "NUMBER_OF_SPARK_PLUGS_BY_KIT": "Quantidade de velas",
    "NUMBER_OF_UNITS_BY_KIT": "Unidades por pacote",
    "NUMBER_TEETH_HUB_SIDE": "Dentes lado cambio",
    "NUMBER_TEETH_WHEEL_SIDE": "Dentes lado roda",
    "OCCASIONS": "Ocasiões",
    "OEM": "OEM",
    "OFFSET": "Offset",
    "OIL_FILLER_CAP_INCLUDED": "Tampa de enchimento de óleo incluída",
    "OIL_FILTER_TYPE": "Tipo de filtro de óleo",
    "OIL_LEVEL_SENSOR_PORT_INCLUDED": "Porta do sensor do nível de óleo incluída",
    "OIL_RINGS_KIT_INCLUDED": "Kit de anéis de aceite incluído",
    "OPENING_TEMPERATURE": "Temperatura de abertura",
    "OPERATION_TYPE": "Tipo de operação",
    "ORIGIN": "Origem",
    "OUTBOARD_SPLINE_QUANTITY": "Quantidade de estrias externas",
    "OUTER_DIAMETER": "Diâmetro externo",
    "OUTER_MATERIALS": "Materiais do exterior",
    "OUTER_RING_WIDTH": "Largura do anel externo",
    "OUTER_SLEEVE_MATERIAL": "Material da capa",
    "OUTER_TIE_ROD_END_LENGTH": "Comprimento",
    "OUTLET_CONNECTION_DIAMETER": "Diâmetro do conector de saída",
    "OUTLET_DIAMETER": "Diâmetro de saída",
    "OUTLET_FITTING_GENDER": "Gênero da conexão de saída",
    "OUTLET_HOLE_NUMBER": "Quantidade de dutos de saída",
    "OUTPUT_CURRENT": "Corrente de saída",
    "OUTPUT_SIZE": "Diâmetro de saida",
    "OUTSIDE_DIAMETER": "Diâmetro externo",
    "OUTSOLE_MATERIAL": "Material da sola",
    "OXYGEN_SENSOR_OVERALL_LENGTH": "Comprimento total",
    "OXYGEN_SENSOR_THREAD_DIAMETER": "Diámetro da rosca",
    "OXYGEN_SENSOR_THREAD_SIZE": "Tamanho da rosca",
    "OXYGEN_SENSOR_WIRE_QUANTITY": "Quantidade de cabos",
    "PACKS_NUMBER": "Quantidade de kits",
    "PAIRS_NUMBER": "Quantidade de pares",
    "PARKING_BRAKE_HANDLE_LENGTH": "Comprimento da alavanca de freio de mão",
    "PARKING_BRAKE_HANDLE_MATERIAL": "Material da alavanca de freio de mão",
    "PARKING_SENSOR_WITH_DIGITAL_DISPLAY": "Com display digital",
    "PART_NUMBER": "Número de peça",
    "PARTS_INCLUDED": "Peças incluídas",
    "PASSENGER_WIPER_MEASURE": "Medida da palheta acompanhante",
    "PATTERN_NAME": "Nome do desenho",
    "PIECES_NUMBER": "Quantidade de peças",
    "PIN_AND_BUSHINGS_INCLUDED": "Pasador e buchas incluídos",
    "PINS_NUMBER": "Quantidade de pinos",
    "PISTONS_NUMBER": "Quantidade de pistãos",
    "POCKETS_CLOSURE_TYPES": "Tipos de fechamento dos bolsos",
    "POCKETS_NUMBER": "Quantidade de bolsos",
    "POLES_NUMBER": "Quantidade de pólos",
    "PORT_OUTSIDE_DIAMETER": "Diâmetro externo da porta",
    "PORT_QUANTITY": "Quantidade de portas",
    "POSITION": "Posição",
    "POSITIONS_NUMBER": "Quantidade de posições",
    "POWER_OUTPUT": "Potência de saída",
    "POWER_STEERING_FLUID_RESERVOIR_MATERIAL": "Material do reservatório de óleo de direção hidráulica",
    "POWER_STEERING_PUMP_INLET_DIAMETER": "Diâmetro do porto de entrada da bomba de direção",
    "POWER_STEERING_PUMP_OUTLET_DIAMETER": "Diâmetro do porto de saída da bomba de direção",
    "POWER_STEERING_PUMP_PULLEY_INCLUDED": "Polia da bomba de direção incluída",
    "POWER_STEERING_PUMP_TYPE": "Tipo de bomba de direção",
    "PRESSURE_CAPACITY": "Capacidade de pressão",
    "PRESSURE_GAUGE_TYPE": "Tipo de manômetro",
    "PRIMARY_OUTLET_DIAMETER": "Diâmetro da saída principal",
    "PRIMARY_OUTLET_THREAD_DIAMETER": "Diâmetro da rosca da saída principal",
    "PRIMARY_SHOE_FRICTION_MATERIAL_LENGTH": "Comprimento do material de fricção da sapata primária",
    "PRIMARY_SHOE_FRICTION_MATERIAL_THICKNESS": "Espessura do material de fricção da sapata primária",
    "PRINT_DESIGN": "Desenho impresso",
    "PRODUCT_TYPE": "Tipo de produto",
    "PROPELLER_PUMP_MATERIAL": "Material do rotor",
    "PUMP_INCLUDED": "Bomba incluída",
    "PUMP_TYPE": "Tipo de bomba",
    "QUANTITY_OF_INLETS": "Quantidade de entradas",
    "QUANTITY_OF_OUTLETS": "Quantidade de saídas",
    "QUANTITY_OF_PADS": "Quantidade de pastilhas",
    "QUANTITY": "Quantidade",
    "RACK_AND_PINION_TYPE": "Tipo de caixa de direção",
    "RADIATOR_CAP_INCLUDED": "Inclui tampa",
    "RADIATOR_CAP_MATERIAL": "Material da tampa do radiador",
    "RADIATOR_MATERIAL": "Material",
    "RADIATOR_SUPPORTS_INCLUDED": "Suportes de radiador incluídos",
    "RADIO_CONTROLS_INCLUDED": "Controles de estéreo incluídos",
    "REAR_BUMPER_FINISH": "Terminação",
    "REAR_BUMPER_MATERIAL": "Material",
    "REAR_WIPER_MEASURE": "Medida da palheta traseira",
    "RECOMMENDED_PETS": "Animais recomendados",
    "RECOMMENDED_SPEAKER_DIAMETER": "Diâmetro recomendado do alto-falante",
    "RECOMMENDED_SPORTS": "Esportes recomendados",
    "RECOMMENDED_USES": "Usos recomendados",
    "REFRIGERANT_TYPE": "Tipo de refrigerante",
    "REGULATOR_TYPE": "Tipo de regulador",
    "RELAXED_LENGTH": "Comprimento aberto",
    "RELEASE_SEASON": "Temporada de lançamento",
    "RELEASE_YEAR": "Ano de lançamento",
    "REQUIRES_PROGRAMMING": "Requer programação",
    "RESERVOIR_CAPACITY": "Capacidade",
    "RESERVOIR_INCLUDED": "Depósito de líquido incluído",
    "RIM_CENTER_INCLUDED": "Inclui calota",
    "RIM_DIAMETER": "Aro",
    "RIM_DIAMETER": "Diâmetro da roda",
    "RIM_MATERIAL": "Material",
    "RIM_WIDTH": "Largura",
    "RIMS_NUMBER": "Quantidade de rodas",
    "RMS_POWER": "Potência RMS",
    "ROD_DIAMETER": "Diâmetro da biela",
    "ROD_THREAD_SIZE": "Rosca passo",
    "ROLL_BAR_COLOR": "Cor da barra de rolagem",
    "ROLL_BAR_HEIGHT": "Altura da barra de rolagem",
    "ROLL_BAR_LENGTH": "Comprimento da barra de rolagem",
    "ROTATING_HEAD": "Cabeça rotativa",
    "ROTATION_DIRECTION": "Sentido de rotação",
    "SAFETY_REGULATIONS": "Normas de segurança",
    "SALE_FORMAT": "Formato de venda",
    "SCREEN_DIAMETER": "Diâmetro do filtro",
    "SEALED_CLUTCH_BEARING": "Rolamento de embreagem selado",
    "SEALS_INCLUDED": "Selos incluídos",
    "SEASON": "Temporada",
    "SEAT_TYPES": "Tipos de bancos",
    "SECONDARY_OUTLET_THREAD_DIAMETER": "Diâmetro da rosca da saída secundária",
    "SECONDARY_SHOE_FRICTION_MATERIAL_LENGTH": "Comprimento do material de fricção da sapata secundária",
    "SECONDARY_SHOE_FRICTION_MATERIAL_THICKNESS": "Espessura do material de fricção da sapata secundária",
    "SECTION_WIDTH": "Largura de secção",
    "SECURITY_SYSTEM_TYPE": "Tipo de sistema de segurança",
    "SELF_ALIGNING": "Auto-compensador",
    "SENSOR_TOTAL_LENGTH": "Comprimento total do sensor",
    "SENSOR_WIRING_INCLUDED": "Cablagem do sensor incluído",
    "SERVICE_CATEGORY": "Categoría de serviço",
    "SERVICE_TYPE": "Tipo de serviço",
    "SHAFT_DIAMETER": "Diâmetro do eixo",
    "SHAPE": "Forma",
    "SHIMS_INCLUDED": "Calços incluídos",
    "SHIRT_COLLAR": "Gola",
    "SHIRT_MATERIAL": "Material",
    "SHOCK_MOUNT_INSOLATOR_MATERIAL": "Material do coxim amortecedor",
    "SHOULDER_STRAP_COLOR": "Cor da correia de ombro",
    "SIDE_POSITION": "Lado",
    "SIDE_POSITIONS": "Lados",
    "SIDE": "Lado",
    "SIZE": "Tamanho",
    "SKIRT_POSITIONS": "Posições",
    "SLEEVE_LENGTH": "Comprimento da manga",
    "SLEEVE_TYPE": "Tipo de manga",
    "SMALL_HOLE_DIAMETER": "Diâmetro do orifício pequeno",
    "SOCKS_TYPE": "Tipo de meias",
    "SOUND_LEVEL": "Nível sonoro",
    "SPARK_PLUGS_POSITION": "Posição das velas de ignição",
    "SPEAKERS_NUMBER": "Quantidade de alto-falantes",
    "SPEEDS_NUMBER": "Quantidade de velocidades",
    "SPLINE_QUANTITY": "Quantidade de estrias",
    "SPORT_BOTTLE_CAPACITY": "Capacidade da garrafa",
    "SPRING_POSITION": "Posição",
    "STARTER_VOLTAGE": "Voltagem",
    "STEERING_COLUMN_LENGTH": "Comprimento da coluna de direção",
    "STEERING_TUBE_DIAMETER": "Diâmetro do tubo de direção",
    "STEERING_WHEEL_GRIP_MATERIAL": "Material do agarre",
    "STEERING_WHEEL_TYPE": "Tipo",
    "STEM_DIAMETER": "Diâmetro da haste",
    "STICKER_SHAPE": "Forma do adesivo",
    "STICKERS_NUMBER": "Quantidade de adesivos",
    "STUD_THREAD_DIAMETER": "Diâmetro da rosca do parafuso",
    "SUGGESTED_USE": "Uso sugerido",
    "SUPPORT_ADJUSTMENT": "Fixação do suporte",
    "SUPPORT_MOUNT": "Montagem do suporte",
    "SUPPORTED_BICYCLES_NUMBER": "Quantidade de bicicletas suportadas",
    "SURFACE_ANGLE": "Ângulo da superfície",
    "SURFACE_FINISH": "Acabamento da superfície",
    "SURFACE_MATERIALS": "Materiais da superfície",
    "SUSPENSION_BALL_JOINT_OUTSIDE_DIAMETER": "Diâmetro externo do pivô de suspensão",
    "SUSPENSION_BALL_JOINT_POSITION": "Posição do pivô de suspensão",
    "SUSPENSION_CONTROL_ARM_BUSHING_TYPE": "Tipo de bucha de controle de suspensão",
    "SWITCH_AMPERAGE_RANGE": "Rango de amperagem do interruptor",
    "SYSTEM_PRESSURE": "Pressão do sistema",
    "T_SHIRT_COLLAR_TYPE": "Tipo de gola",
    "TEETH_NUMBER": "Quantidade de dentes",
    "TERMINAL_GENDER": "Gênero do terminal",
    "TERMINAL_QUANTITY": "Quantidade de terminais",
    "TERMINAL_THREAD_DIAMETER": "Diâmetro da rosca do terminal",
    "TERMINAL_TYPE": "Tipo de terminal",
    "TERMINALS_NUMBER": "Quantidade de terminais",
    "TERMINATION": "Terminação",
    "TERRAIN_TYPE": "Tipo de terreno",
    "THERMO_CAPACITY": "Capacidade da garrafa térmica",
    "THERMO_COLD_DRINK_TEMPERATURE_CONSERVATION_TIME": "Tempo de conservação da bebida fria",
    "THERMO_HOT_DRINK_TEMPERATURE_CONSERVATION_TIME": "Tempo de conservação da bebida quente",
    "THICKNESS": "Espessura",
    "THREAD_DIAMETER": "Diâmetro da rosca",
    "THREAD_GENDER": "Rosca",
    "THREAD_LENGTH": "Comprimento da rosca",
    "THREAD_MEASUREMENT": "Passo da rosca",
    "THREAD_SIZE": "Tamanho da rosca",
    "TIMING_BELT_TENSIONER_PULLEY_INSIDE_DIAMETER": "Diâmetro interno do tensor da correia dentada",
    "TIMING_BELT_TENSIONER_PULLEY_MATERIAL": "Material do tensor da correia dentada",
    "TIMING_BELT_TENSIONER_PULLEY_OUTSIDE_DIAMETER": "Diâmetro externo do tensor da correia dentada",
    "TIMING_BELT_TENSIONER_PULLEY_WIDTH": "Largura do tensor da correia dentada",
    "TIMING_BELTS_BRAND": "Marca da correia",
    "TIRE_CONSTRUCTION_TYPE": "Tipo de construção",
    "TIRES_NUMBER": "Quantidade de pneus",
    "TORSION_BAR_LENGHT": "Comprimento",
    "TOTAL_DEPTH": "Profundidade total",
    "TOTAL_LENGTH": "Comprimento total",
    "TRACTION_TYPE": "Tipo de tração",
    "TRANSMISSION_TYPE": "Tipo de transmissão",
    "TRUMPETS_NUMBER": "Quantidade de cornetas",
    "TUBE_DIAMETER": "Diâmetro do tubo",
    "TUBE_MATERIAL": "Material do tubo",
    "TYPE_OF_PROTECTION": "Tipo de proteção",
    "UNIT_TYPE": "Tipo de unidade",
    "UNIT_VOLUME": "Volume da unidade",
    "UNITS_PER_PACK": "Unidades por kit",
    "UNITS_PER_PACKAGE": "Unidades por embalagem",
    "USE_TYPE": "Tipo de uso",
    "UTILIZATION_CATEGORIES": "Categorias de utilização",
    "UTQG": "UTQG",
    "VACUUM_PORT_DIAMETER": "Diâmetro das portas de vácuo",
    "VACUUM_PORT_QUANTITY": "Quantidade de portas de vácuo",
    "VACUUM_TUBE_FUNCTION": "Função da válvula eletrônica",
    "VACUUM_TUBES_NUMBER": "Quantidade de válvulas eletrônicas",
    "VALVES_GUIDE_INCLUDED": "Guias de válvulas incluídos",
    "VALVES_NUMBER": "Quantidade de válvulas",
    "VEHICLE_AXIS_POSITION": "Posição do eixo",
    "VEHICLE_SPEAKER_DIAMETER": "Diâmetro do alto-falante",
    "VEHICLE_SPEAKER_LENGTH": "Comprimento do alto-falante",
    "VEHICLE_SPEAKER_TYPE": "Tipo de alto-falante",
    "VEHICLE_SPEAKER_WIDTH": "Largura do alto-falante",
    "VEHICLE_TYPE": "Tipo de veículo",
    "VEHICLE_TYPES": "Tipo de veículo",
    "VIDEO_RESOLUTION": "Resolução de vídeo",
    "VISCOSITY_GRADE": "Grau de viscosidade",
    "VISION_ANGLE": "Ângulo de visão",
    "VOLTAGE": "Voltagem",
    "VOLUME_CAPACITY": "Capacidade em volume",
    "WAIST_CIRCUMFERENCE": "Contorno da cintura",
    "WATER_AND_DUST_RESISTANCE_CLASSIFICATION": "Classificação de resistência à água e ao pó",
    "WATER_PUMP_BRAND": "Marca da bomba d\'água",
    "WATER_PUMP_INCLUDED": "Inclui bomba d\'água",
    "WATER_PUMP_MATERIAL": "Material do corpo",
    "WATER_TEMPERATURE_PLUG": "Com cebolão",
    "WAYS_NUMBER": "Quantidade de vias",
    "WEIGHT_CAPACITY": "Capacidade em peso",
    "WEIGHT_LIMIT": "Peso máximo suportado",
    "WEIGHT": "Peso",
    "WET_TRACK_GRIP": "Aderência em pista molhada",
    "WHEEL_COVER_DIAMETER": "Diâmetro",
    "WHEEL_STRUCTURE": "Estrutura da roda",
    "WIDTH": "Largura",
    "WIRE_LENGTH": "Comprimento do cabo",
    "WIRING_INCLUDED": "Cabo incluído",
    "WITH_ADJUSTABLE_CLOSURE": "Fechamento ajustável",
    "WITH_ADJUSTABLE_HEIGHT": "Altura ajustável",
    "WITH_ADJUSTABLE_OUTPUT_VOLTAGE": "Voltagem de saída ajustável",
    "WITH_AIR_CONDITIONER": "Ar condicionado",
    "WITH_ALARM": "Alarme",
    "WITH_ANTI_DRIP_SYSTEM": "Sistema antigotejamento",
    "WITH_ANTI_THEFT_MECHANISM": "Mecanismo antirroubo",
    "WITH_ARMOR": "Proteção",
    "WITH_BLIND_SPOT_DETECTION": "Sensor de ponto cego",
    "WITH_BLUETOOTH": "Bluetooth",
    "WITH_BRAND_LOGO": "Logo da marca",
    "WITH_BUZZER": "Buzzer",
    "WITH_CAMERA": "Câmera",
    "WITH_CAR_CUP_HOLDER": "Porta-copos",
    "WITH_CENTRAL_GLOVE_BOX": "Porta-luvas central",
    "WITH_CLOSING": "Fecho",
    "WITH_DETACHABLE_HANDLE": "Alça destacável",
    "WITH_FM_RADIO": "Rádio FM",
    "WITH_FOG_LIGHT_HOLE": "Buraco para farol de milha",
    "WITH_GPS": "GPS",
    "WITH_HANDLE": "Alça",
    "WITH_HAZARD_WARNING_SWITCH": "Interruptor de piscas-alertas",
    "WITH_HOOD": "Capuz",
    "WITH_ICE_CONTAINER": "Recipiente para gelo",
    "WITH_ILLUMINATION": "Iluminação",
    "WITH_INFUSER": "Infusor",
    "WITH_INTERNAL_LINING": "Forro interno",
    "WITH_INTERNAL_ZIP_POCKET": "Bolso interno com fecho",
    "WITH_LAPTOP_SLEEVE": "Compartimento para notebook",
    "WITH_LCD_DISPLAY": "Tela LCD",
    "WITH_LED_BULBS_FOR_NIGHT_VISION": "Lampadas LED para visão noturna",
    "WITH_LED_LIGHT": "Luz LED",
    "WITH_LID": "Tampa",
    "WITH_LIGHT": "Luz",
    "WITH_LOCK": "Fechadura",
    "WITH_MICROPHONE": "Microfone",
    "WITH_MIRROR": "Espelho",
    "WITH_NOISE_REDUCTION_SYSTEM": "Sistema de redução de ruído",
    "WITH_NON_SLIP_SURFACE": "Superfície antiderrapante",
    "WITH_PARKING_LIGHTS": "Luzes de posição",
    "WITH_POCKETS": "Bolsos",
    "WITH_POLARIZED_LENS": "Lente polarizada",
    "WITH_PORTS_FOR_LIGHTING": "Aberturas para luz",
    "WITH_PRESENCE_SENSOR": "Sensor de presença",
    "WITH_RECYCLED_MATERIALS": "Materiais reciclados",
    "WITH_REMOVABLE_SURFACE": "Superficie removível",
    "WITH_SD_CARD_READER": "Leitor de cartão SD",
    "WITH_SIREN": "Sirene",
    "WITH_STOP_LIGHT": "Luz de freio",
    "WITH_STRAW": "Canudo",
    "WITH_TOUCHSCREEN": "Tela touch",
    "WITH_TV_FUNCTION": "Função de TV",
    "WITH_USB_PORT": "Entrada USB",
    "WITH_USB": "USB",
    "WITH_WHEELS": "Rodas",
    "WORKING_PRESSURE": "Pressão de trabalho",
    "WRIST_PIN_JOURNAL_BUSHING_DIAMETER": "Diâmetro do munhão da biela",
    "XENON_LIGHT_VOLTAGE": "Voltagem",
}