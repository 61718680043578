export const getWebsiteIndexKey = (storeName) => {
    // Remove spaces by default
    let key = storeName.replace(/\s+/g, '');
    
    // Add specific mappings here
    const storeMapping = {
        'Belcar Fiat': 'BelcarFiat',
        'Peças Kia': 'Barao',
        'Itavema Renault': 'ItavemaRenault',
        'Itavema Hyundai': 'ItavemaHyundai',
        'Itavema Toyota': 'ItavemaToyota',
        'Itavema Volvo': 'ItavemaVolvo',
        'Itavema Byd': 'ItavemaByd',
        'Itavema Fiat': 'ItavemaFiat',
        'Peças Topcar': 'Topcar'
    };

    return storeMapping[storeName] || key;
};

export const buildHelmetData = (configData, websitesIndexData, options = {}) => {
    const {
        pageName = '',
        allowIndex = true,
        additionalKeywords = '',
    } = options;

    const storeKey = getWebsiteIndexKey(configData.defaultStore);
    const storeData = websitesIndexData[storeKey];

    if (!storeData) {
        console.error(`No data found for store: ${configData.defaultStore} (key: ${storeKey})`);
        return null;
    }

    const pageTitle = pageName ? `${pageName} - ${storeData.title}` : storeData.title;
    const pageDescription = pageName ? 
        `${pageName} - ${storeData.description}` : 
        storeData.description;

    return {
        title: pageTitle,
        description: pageDescription,
        robots: allowIndex ? "index, follow" : "noindex, nofollow",
        canonical: `${storeData.canonical}${pageName.toLowerCase().replace(/\s+/g, '-')}`,
        social: {
            type: 'website',
            url: storeData.canonical,
            title: pageTitle,
            description: pageDescription,
            image: storeData.social_imgUrl
        },
        keywords: `peças originais, ${configData.defaultStore}, ${additionalKeywords}`.trim()
    };
};