import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../App.css';
import './HeaderNavigation.css';
import CartIcon from "@mui/icons-material/ShoppingCart";
import { auth } from '../firebase';
import { Home, Login, Menu } from '@mui/icons-material';
import SideAccountMenu from './SideAccountMenu';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ClickOutside from '../Utilities/ClickOutside';
import VehicleFilter from './VehicleFilter';
import { contentData, configData } from '../Whitelabel/websitesData';
import SearchIcon from "@mui/icons-material/Search";
import WhatsApp from '@mui/icons-material/WhatsApp';
import { handleWhatsappClick } from '../Utilities/Whatsapp';

function HeaderNavigation() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const [showWhatsappButton, setShowWhatsappButton] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const cartItems = useSelector(state => state.cart.carrinho);

  useEffect(() => {
    // Function to update the width
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // console.log('configData.defaultStore: ', configData.defaultStore);
    if (
      configData.defaultStore === 'Belcar Fiat' ||
      configData.defaultStore === 'Asuka'
    ) {
      setShowWhatsappButton(true);
    }
  }, [configData.defaultStore]);

  const calculateTotalQuantity = () => {
    return cartItems.reduce((total, item) => total + item.itemQty, 0);
  };

  const totalQuantity = calculateTotalQuantity();

  const handleUserAccount = async () => {
    try {
      auth.onAuthStateChanged((user) => {
        setUser(user);
      });
    } catch (error) {
      console.error('Erro ao obter usuário:', error.message);
    }
  };

  const openSideMenu = () => {
    setIsSideMenuOpen(true);
  }
  
  const closeSideMenu = () => {
    setIsSideMenuOpen(false);
  }

  useEffect(() => {
    handleUserAccount();
  }, []);

  // useEffect(() => {
  //   console.log('isSideMenuOpen: ', isSideMenuOpen);
  // }, []);

  const noop = () => {};

  const handleWhatsappButtonClick = () => {
    handleWhatsappClick(null, null, null, null, null, null);
  }

  return (
    <nav>
      <ul className='center'>

        {(configData.defaultStore === 'UmuaramaHD' || configData.defaultStore === 'UmuaramaMotos') &&
          <>
            {/* <li className='desktopNavRow hiddenOn500'>
              <NavLink to='/produtos/Peças' className='buttonStyle headerNavButton'>Peças</NavLink>
            </li>
            <li className='desktopNavRow hiddenOn500'>
              <NavLink to='/produtos/Boutique' className='headerNavButton'>Boutique</NavLink>
            </li>
            <li className='desktopNavRow hiddenOn500'>
              <NavLink to='/produtos/Acessórios' className='headerNavButton'>Acessórios</NavLink>
            </li> */}
            <li className='desktopNavRow hiddenOn500'>
              <NavLink to='/Categorias' className='headerNavButton'>Categorias</NavLink>
            </li>


            {/* <li className='desktopNavRow hiddenOn500'>
              <NavLink to='/produtos/KTM' className='headerNavButton'>KTM</NavLink>
            </li> */}
            {/* <li className='desktopNavRow hiddenOn500'>
              <SearchIcon/>
            </li> */}
          </>
        }

        {/* <VehicleFilter/> */}
        <li className='hiddenOn500'>
          <NavLink to="/checkout" className='headerNavButton' activeclassname="active" style={{position: 'relative'}}>
            {cartItems.length > 0 &&
              <div className='cartItemCounter fadeIn'>{totalQuantity}</div>
            }
            <CartIcon/>
          </NavLink>
        </li>
        {windowWidth < 600
        ?
        <li>
          <div onClick={openSideMenu} className='headerNavButton' style={{color: '#b6b6b6', position: 'relative'}}>
            {/* <Home/> */}
            {cartItems.length > 0 &&
              <div className='cartItemCounter fadeIn'>{totalQuantity}</div>
            }
            <Menu/>
          </div>
        </li>
        :
        <>
          {showWhatsappButton &&
            <li className='desktopNavRow' style={{marginRight: 0}}>
              <div className='headerWhatsappButton' onClick={handleWhatsappButtonClick}>
                <WhatsApp style={{fontSize: '24px'}}/>
              </div>
            </li>
          }
          <li className='desktopNavRow'>
            {/* <NavLink to='/pedidos' className='buttonStyle headerNavButton' activeclassname="active">Pedidos</NavLink> */}
            <NavLink to='/minha-conta' className='buttonStyle headerNavButton' activeclassname="active">
              {user ? 'Minha Conta' : 'Entrar'}
            </NavLink>
          </li>
        </>
        }
      </ul>
      <ClickOutside onOutsideClick={isSideMenuOpen ? closeSideMenu : noop}>
        <SideAccountMenu
          isOpen={isSideMenuOpen}
          closeButton={closeSideMenu}
        />
      </ClickOutside>
    </nav>
  );
}

export default HeaderNavigation;