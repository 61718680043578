import React from 'react';
import Button from '../Common/Button';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { configData } from '../Whitelabel/websitesData';
import { Helmet } from 'react-helmet';
import websitesIndexData from '../websitesIndexData';
import { buildHelmetData } from '../Utilities/HelmetTool';

function Shipping() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    // Add helmetData using the unified utility
    const helmetData = buildHelmetData(configData, websitesIndexData, {
        pageName: 'Entrega',
        allowIndex: false, // Shipping policy should not be indexed for SEO
        additionalKeywords: 'política de entrega, frete, prazo de entrega, envio, transportadora, entrega de produtos'
    });

    return (
        <div className='main fadeIn'>
            {helmetData && (
                <Helmet>
                    <title>{helmetData.title}</title>
                    <meta name="description" content={helmetData.description} />
                    
                    <meta name="robots" content={helmetData.robots} />
                    <meta name="googlebot" content={helmetData.robots} />
                    
                    <meta property="og:type" content={helmetData.social.type} />
                    <meta property="og:url" content={helmetData.social.url} />
                    <meta property="og:title" content={helmetData.social.title} />
                    <meta property="og:description" content={helmetData.social.description} />
                    <meta property="og:image" content={helmetData.social.image} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={helmetData.social.url} />
                    <meta property="twitter:title" content={helmetData.social.title} />
                    <meta property="twitter:description" content={helmetData.social.description} />
                    <meta property="twitter:image" content={helmetData.social.image} />
                    
                    <meta name="keywords" content={helmetData.keywords} />
                    <link rel="canonical" href={helmetData.canonical} />
                </Helmet>
            )}
            <div className='pageContent'>
                <div className='card'>
                <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                        <h2>Política de Entrega de Produtos</h2>
                        <h3>1. Identificação</h3>
                        <p>A MEGA STORE localizada na Avenida Barão Homem de Melo 3030 possui uma infraestrutura modernizada, buscando melhorar a prestação de serviços e o alto padrão de atendimento aos clientes KIA em Minas Gerais.</p>

                        <h3>2. Contato</h3>
                        <p>Central de Atendimento Caso o Usuário necessite de qualquer informação, esclarecimento ou atendimento com relação a esta Política de Entrega de Produtos, a Kia Barão disponibiliza uma Central de Atendimento para receber todas as comunicações que o Usuário desejar fazer.</p>
                        <p>A Central de Atendimento opera pelo email <a href="mailto:lojaonline@pecaskia.com.br">lojaonline@pecaskia.com.br</a>.</p>
                        <p>Segunda à Sexta das 08h00 às 18h00</p>
                        <p>O Usuário receberá, pelo mesmo canal de comunicação, uma confirmação imediata do recebimento da sua demanda, que será tratada e respondida em um prazo de cinco dias.</p>
                        <p>Além disso, a Kia Barão disponibiliza neste Site as demais políticas: Política de Trocas e Devoluções, Política de Pagamentos, Política de Segurança e Privacidade e, finalmente, os Termos e Condições de Uso deste Site.</p>

                        <h3>3. Locais atendidos</h3>
                        <p>A Kia Barão opera, diretamente ou por meio de terceiros contratados, serviços de entrega em todo o País, dentro dos limites da empresa transportadora contratada, Correios. No entanto, a Kia Barão não disponibiliza entrega de produtos adquiridos neste Site em territórios estrangeiros.</p>

                        <h3>4. Prazos de entrega</h3>
                        <p>Durante o processo de Compra, Kia Barão informará ao Cliente uma estimativa de prazo para a entrega dos Produtos. Essa estimativa leva em conta os produtos adquiridos, as suas quantidades, o estoque disponível e a distância entre nossas centrais de distribuição e o endereço de entrega informado pelo Cliente. O prazo de entrega será contado a partir da data de aprovação do pedido, que pode ocorrer de 3 (três) dias a 5 (cinco) dias úteis para pagamentos por cartão de crédito. O prazo de entrega informado ao Cliente será o prazo determinado pela empresa transportadora contratada, Correios.</p>

                        <h3>5. Horário de entrega e agendamento</h3>
                        <p>A Kia Barão efetua as entregas de Produtos de acordo com a Política de entrega da transportadora contratada, Correios.</p>

                        <h3>6. Valor do frete</h3>
                        <p>O valor do frete da entrega dos Produtos é calculado automaticamente pelo Site no momento da Compra, a partir do peso, volume e quantidade dos Produtos adquiridos, assim como da distância entre nossos centros de distribuição e o local de entrega informado pelo Cliente. A seu exclusivo critério, a Kia Barão poderá disponibilizar em seu Site categorias especiais de frete, específicas para alguns produtos e para algumas localidades, de forma definitiva ou temporária. Neste caso, é possível que cada categoria ofereça preços e prazos de entrega diferentes, cabendo ao Cliente escolher aquela categoria que atenda a suas expectativas.</p>

                        <h3>7. Condições da entrega</h3>
                        <p>A Kia Barão entregará os Produtos adquiridos em sua embalagem original. Considerando que os entregadores da Kia Barão não são autorizados a realizar qualquer atividade que seja diversa da simples entrega do Produto. Em edifícios, a entrega será efetuada no local da portaria, sendo o Cliente exclusivamente responsável pela guarda e transporte dos produtos até seu andar e unidade. Para que a entrega seja realizada, é necessário que o Cliente autorize uma pessoa maior de 18 anos (amigos, parentes, porteiros ou outra) para receber e conferir os produtos e assinar o respectivo protocolo. O serviço de entrega não inclui qualquer outra atividade por parte dos entregadores, estando a Kia Barão isenta de qualquer obrigação de teste dos produtos adquiridos e entregues.</p>

                        <h3>8. Recusa de recebimento</h3>
                        <p>O Cliente poderá e deverá verificar os seguintes aspectos no momento do recebimento: (i) se a embalagem estiver aberta, violada ou danificada; (ii) se o Produto estiver avariado pelo transporte, aberto, violado ou usado; (iii) se os Produtos entregues não corresponderem aos Produtos adquiridos pelo Site e/ou à nota fiscal; ou, ainda, (iv) se o conteúdo das embalagens estiver incompleto, sem partes ou acessórios dos produtos adquiridos. Em caso de qualquer divergência, o Cliente deverá recusar-se a receber o Produto em questão e entrar em contato com a Central de Atendimento para imediata solução do problema identificado. Caso haja o recebimento indevido do produto, o Cliente poderá requerer sua troca apenas nos casos previstos na Política de Trocas e Devoluções.</p>

                        <h3>9. Tentativas de entrega</h3>
                        <p>A Kia Barão, através da transportadora contratada, Correios, entregará os Produtos adquiridos, no prazo estabelecido e no endereço informado pelo Cliente. Caso não seja possível a Kia Barão realizar a entrega dos Produtos por ausência do Cliente, de pessoa autorizada, por restrições de horário do condomínio ou por limitações físicas que impeçam a entrega ou, ainda, por erro do Cliente em indicar o local correto da entrega no ato da compra dos Produtos, a Kia Barão, através da transportadora contratada, Correios, fará mais duas tentativas subsequentes, ou de acordo com a política adotada pela transportadora. Caso as três tentativas de entrega fracassem, os produtos adquiridos serão devolvidos à central de distribuição, o pedido será cancelado e o reembolso ocorrerá nos mesmos termos previstos na Política de Trocas e Devoluções com relação à devolução por arrependimento (item 4.5 da referida Política).</p>

                        <h3>10. Diversos</h3>
                        <p>Alterações do pedido. A Kia Barão não aceitará alterações do pedido (formas de pagamento, quantidades ou local de entrega) após a finalização do processo de Compra no Site.</p>
                        <p>Atrasos. Algumas situações excepcionais podem causar atrasos na entrega dos Produtos adquiridos, como a indicação de um endereço errado, incompleto ou inexistente ou a ocorrência de fatores naturais (chuvas, enchentes) ou humanos (greves, manifestações, acidentes), conhecidos como eventos de caso fortuito e força maior. Nestes casos, a Kia Barão através da transportadora contratada, Correios fará o possível para manter o Cliente informado com relação ao problema que causou o atraso e para efetivar a entrega no menor prazo possível.</p>
                        <p>Retenções. Caso os Produtos adquiridos sejam retidos por autoridades fiscais em decorrência de pendências do Cliente, a Kia Barão informará a situação imediatamente, ficando, no entanto, isenta do dever de entregar, uma vez que os Produtos só poderão ser liberados pela autoridade competente mediante o comparecimento do Cliente.</p>
                        <p>Alertas. A Kia Barão poderá enviar e-mails ou mensagens SMS ao Cliente a fim de informar qualquer alteração no processo de entrega de seu pedido, como a saída dos produtos do centro de distribuição, o deslocamento pela cidade, o horário estimado de entrega, eventuais atrasos, entre outros.</p>
                        <Button
                            className='buttonColorSecond smallButton'
                            label='voltar'
                            onClick={handleGoBack}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Shipping;