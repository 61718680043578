import React from 'react';
import Button from '../Common/Button';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import { storeInfo } from '../Utilities/Placeholders';
import { configData } from '../Whitelabel/websitesData';
import { Helmet } from 'react-helmet';
import websitesIndexData from '../websitesIndexData';
import { buildHelmetData } from '../Utilities/HelmetTool';

function Refund() {
    const navigate = useNavigate();

    const handleGoBack = () => {
      navigate(-1);
    };

    // Add helmetData using the unified utility
    const helmetData = buildHelmetData(configData, websitesIndexData, {
        pageName: 'Reembolso',
        allowIndex: false, // Refund policy should not be indexed for SEO
        additionalKeywords: 'política de reembolso, devolução, cancelamento, troca de produtos, garantia'
    });

    return (
        <div className='main fadeIn'>
            {helmetData && (
                <Helmet>
                    <title>{helmetData.title}</title>
                    <meta name="description" content={helmetData.description} />
                    
                    <meta name="robots" content={helmetData.robots} />
                    <meta name="googlebot" content={helmetData.robots} />
                    
                    <meta property="og:type" content={helmetData.social.type} />
                    <meta property="og:url" content={helmetData.social.url} />
                    <meta property="og:title" content={helmetData.social.title} />
                    <meta property="og:description" content={helmetData.social.description} />
                    <meta property="og:image" content={helmetData.social.image} />
                    
                    <meta property="twitter:card" content="summary_large_image" />
                    <meta property="twitter:url" content={helmetData.social.url} />
                    <meta property="twitter:title" content={helmetData.social.title} />
                    <meta property="twitter:description" content={helmetData.social.description} />
                    <meta property="twitter:image" content={helmetData.social.image} />
                    
                    <meta name="keywords" content={helmetData.keywords} />
                    <link rel="canonical" href={helmetData.canonical} />
                </Helmet>
            )}
            <div className='pageContent'>
                <div className='card'>
                    <div className='column legalTerm' style={{padding: '20px', gap: '10px'}}>
                        <h2>Reembolso</h2>
                        <p>Ressarcimento de valores de pedidos cancelados</p>
                        <p>A {configData.defaultStore} fará a restituição dos valores pagos utilizando à mesma forma de pagamento escolhida no processo de compras:</p>
                        <ul>
                            <li>em compras pagas com CARTÃO DE CRÉDITO, a administradora de cartões será notificada e o estorno ocorrerá em até duas faturas subsequentes.</li>
                            <li>em compras pagas com BOLETO BANCÁRIO, a restituição será efetuada por meio de depósito bancário na conta corrente indicada pelo consumidor, em até 10 (dez) dias úteis. É necessário que o CPF do titular da conta corrente seja o mesmo que consta no pedido (CPF do cliente).</li>
                            <li>IMPORTANTE: em caso de devolução, a restituição dos valores será processada em até 5 dias úteis após o recebimento e análise das condições do(s) produto(s) em nosso Centro de Distribuição.</li>
                        </ul>
                        {(configData.defaultStore !== 'Belcar Fiat' && configData.defaultStore !== 'Asuka')
                            ?
                                <p>O reembolso será realizado no valor total do(s) produto(s) devolvido(s)/cancelado(s), somando-se o valor do frete.</p>
                            :
                                <p>O reembolso será realizado no valor total do(s) produto(s) devolvido(s)/cancelado(s), conforme valores informados de cada item constatado na emissão da Nota fiscal, sem os custos adicionais de envio.</p>
                        }
                        <p>A {configData.defaultStore} isenta-se da obrigação de cancelar ou consertar qualquer produto que apresente claramente indícios de mau uso.</p>
                        
                        <h3>Para cancelar a compra de um produto</h3>
                        <p>Se a forma de pagamento escolhida foi boleto, e o pagamento ainda não foi efetuado, o pedido será cancelado automaticamente no prazo de cinco dias úteis, sem qualquer ônus para você.</p>
                        <p>Caso o pedido tenha sido realizado com outra forma de pagamento, é necessário aguardar a confirmação do pagamento.</p>
                        <p>Para solicitar o cancelamento, favor entrar em contato com o SAC.</p>

                        <h3>Como devolver um produto</h3>
                        <p>O produto deve ser enviado pelos Correios. A {configData.defaultStore} arca com os custos de postagem e fornece a etiqueta de envio da devolução ao cliente. Todas as instruções serão informadas com o SAC.</p>
                        <p>Você deve devolver o produto com a cópia da nota fiscal, descrevendo o motivo da devolução. A ausência de nota fiscal impedirá a identificação de seu pedido e, consequentemente, a troca. É imprescindível que a cópia da nota fiscal seja enviada, junto com o motivo da devolução.</p>

                        {/* <h3>1. Condições Gerais</h3>
                        <p>Todas as ocorrências que envolvam troca ou devolução devem ser comunicadas ao SAC.</p>
                        <p>Produto devolvido sem essa comunicação, fora do prazo ou com ausência de itens/acessórios que o acompanham, será reenviado ao cliente pela {configData.defaultStore}.</p>
                        <p>Os produtos não sofrem qualquer alteração em nosso Centro de Distribuição: eles são enviados ao cliente exatamente como nos foram entregues pelo fabricante ou revendedor.</p>
                        <p>Se ocorrer quaisquer das hipóteses abaixo, recuse o recebimento do produto:</p>
                        <ul>
                            <li>embalagem aberta ou avariada;</li>
                            <li>produto avariado;</li>
                            <li>produto em desacordo com o pedido;</li>
                            <li>falta de acessórios.</li>
                        </ul>
                        <p>Se, ainda assim, você receber o produto, por favor, entre em contato com o SAC em até 7 dias corridos.</p> */}

                        <h3>1. Devolução por Arrependimento/Desistência</h3>
                        <p>Para devolver um produto por arrependimento, as seguintes condições deverão ser observadas:</p>
                        <ul>
                            <li>o prazo para desistir da compra do produto é de até 7 (sete) dias corridos, a contar da data do recebimento.</li>
                            <li>o produto deverá ser encaminhado na embalagem original, sem indícios de uso, sem violação do lacre original do fabricante, acompanhado de nota fiscal, manual e todos os seus acessórios.</li>
                        </ul>

                        <h3>2. Produto com Defeito</h3>
                        {(configData.defaultStore !== 'Belcar Fiat' && configData.defaultStore !== 'Asuka')
                            ?
                                <p>Se o produto adquirido na Loja {configData.defaultStore} apresentar defeito após 7 (sete) dias a contar da data do recebimento, mas dentro do prazo de garantia do fabricante, você deve entrar em contato com o SAC para comunicar a ocorrência e obter esclarecimentos ou dirigir-se a uma das Assistências Técnicas credenciadas pelo próprio fabricante do produto.</p>
                            :
                                <p>Se o produto adquirido na Loja {configData.defaultStore} apresentar defeito após 7 (sete) dias a contar da data do recebimento, mas dentro do prazo de garantia do fabricante, você deve entrar em contato com o SAC para comunicar a ocorrência e obter esclarecimentos.</p>
                        }
                        <p>Os produtos devem ser devolvidos, preferencialmente, na embalagem original. É indispensável que o lacre original do fabricante esteja intacto, e que as mercadorias tenham nota fiscal, manual e todos os acessórios.</p>
                        <p>Todos os produtos são analisados. Eles serão reenviados ao cliente sempre que não for constatado qualquer defeito, ou se tiver sido descumprida uma das condições descritas no tópico anterior. Será cobrado os custos de transporte de reenvio.</p>
                        <p>A garantia contratual é de inteira responsabilidade do fabricante e seu prazo e forma de utilização constam do Termo de Garantia que acompanha o produto.</p>

                        <Button
                            className='buttonColorSecond smallButton'
                            label='voltar'
                            onClick={handleGoBack}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Refund;
