import React, { useState, useEffect } from 'react';
import '../App.css';
import Button from '../Common/Button';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { CheckCircleOutline } from '@mui/icons-material';
import { functions } from '../firebase';
import { configData } from '../Whitelabel/websitesData';
import { httpsCallable } from 'firebase/functions';
import { auth } from '../firebase';
import { useUser } from '../Providers/UserContext';
import { Helmet } from 'react-helmet';
import websitesIndexData from '../websitesIndexData';
import { buildHelmetData } from '../Utilities/HelmetTool';

function ResetPwd() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const {gpuser,setUser} = useUser();
  const [resetSent, setResetSent] = useState(false);

  // Add helmetData using the unified utility
  const helmetData = buildHelmetData(configData, websitesIndexData, {
    pageName: 'Recuperar Senha',
    allowIndex: false, // Password recovery pages should not be indexed
    additionalKeywords: 'recuperação de senha, alterar senha, redefinir senha, esqueci minha senha'
  });

  useEffect(() => {
    if (gpuser || gpuser != null) {
      setEmail(gpuser.email);
    }
  }, [gpuser]);

  const handleEmail = (event) => {
    setEmail(event.target.value);
  }

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const resetClick = () => {
    if (email !== '' && isValidEmail(email)) {
      resetPwd(email);
      setResetSent(true);
    } else {
      alert('Insira um email de uma conta existente');
    }
  }

  const backToLogin = () => {
    navigate('/login');
  }

  const resetPwd = async (email) => {
    const sendResetEmail = httpsCallable(functions, 'sendResetEmail');

    try {
        const response = await sendResetEmail({
            email: email,
            loja: configData.defaultStore,
            linkloja: configData.defaultStoreLink,
            emailloja: configData.defaultContactEmail
        });
        // console.log(response.data); // Handle the response as needed
    } catch (error) {
        console.error("Erro ao enviar email de recuperação de senha: ", error);
        // Handle error (e.g., show a notification to the user)
    }
  }

  return (
    <div className="main">
      {helmetData && (
        <Helmet>
          <title>{helmetData.title}</title>
          <meta name="description" content={helmetData.description} />
          
          <meta name="robots" content={helmetData.robots} />
          <meta name="googlebot" content={helmetData.robots} />
          
          <meta property="og:type" content={helmetData.social.type} />
          <meta property="og:url" content={helmetData.social.url} />
          <meta property="og:title" content={helmetData.social.title} />
          <meta property="og:description" content={helmetData.social.description} />
          <meta property="og:image" content={helmetData.social.image} />
          
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={helmetData.social.url} />
          <meta property="twitter:title" content={helmetData.social.title} />
          <meta property="twitter:description" content={helmetData.social.description} />
          <meta property="twitter:image" content={helmetData.social.image} />
          
          <meta name="keywords" content={helmetData.keywords} />
          <link rel="canonical" href={helmetData.canonical} />
        </Helmet>
      )}
      <div className='pageContent column' style={{alignItems: 'center', gap: '10px'}}>
        <div className='card'>
          <div className='formContainer'>
            {!resetSent
              ?
                <div className='column fadeIn' style={{padding: '20px', gap: '10px'}}>
                  {gpuser ?
                    <h2>Alterar Senha</h2>
                  :
                    <h2>Recuperar Senha</h2>
                  }
                  <input 
                    type='email' 
                    placeholder='email'
                    value={email}
                    onChange={handleEmail}
                    disabled={gpuser !== null}
                  />
                  <Button
                    className='buttonStyle buttonColorMain largeButton'
                    label='Enviar email de recuperação'
                    onClick={resetClick}
                  />
                  <div className='column center'>
                    <NavLink to={'/login'}>
                      Voltar
                    </NavLink>
                  </div>
                </div>
              :
                <div className='column fadeIn' style={{padding: '20px', gap: '10px'}}>
                  <h2>Recuperar Senha</h2>
                  <div className='column  center'>
                    <CheckCircleOutline style={{fontSize: '64px', color: 'var(--highlightGreen)'}}/>
                  </div>
                  <span>Um link de recuperação de senha foi enviado para o seu email :)</span>
                  <Button
                    className='buttonStyle buttonColorMain largeButton'
                    label='Ok'
                    onClick={backToLogin}
                  />
                </div>
            }
          </div>
        </div>
      </div>
      {/* <iframe src='http://localhost:3000/' style={{position: 'absolute', top: 0, left: 0, width: '100%', height: 'calc(100% - 67px)', border: 0}}/> */}
    </div>
  );
}

export default ResetPwd;